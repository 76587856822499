import React from 'react';
import { FunctionComponent, useCallback, ChangeEvent, ReactNode } from 'react';

import { NavCarrierFormControl } from 'app/forms/control/form-field';
import { RadioContext } from 'shared/components/form-fields/radio/radio.context';

interface OwnProps {
  name: string;
  field: NavCarrierFormControl;
  children: ReactNode;
}

type Props = OwnProps;

export const RadioGroup: FunctionComponent<Props> = ({field, name, children}) => {
  const onChange = useCallback((e: ChangeEvent<HTMLInputElement>) => field.setValue(e.target.checked && e.target.value), [field]);
  return (
    <RadioContext.Provider value={{selection: field.value, name, onChange}}>
      {children}
    </RadioContext.Provider>
  );
};
