import React from 'react';
import { ChangeEvent, FocusEvent, FunctionComponent } from 'react';

import { NavCarrierFormControl } from 'app/forms/control/form-field';
import { useSubscribeToFormDisabledChanges, useSubscribeToFormValueChanges } from 'app/hooks/forms/use-update-on-form-changes.hooks';

type InnerFocusEvent = FocusEvent<HTMLInputElement>;
type InnerChangeEvent = ChangeEvent<HTMLInputElement>;

const innerOnBlur = (field: NavCarrierFormControl, outerOnBlur?: (e?: InnerFocusEvent) => any) => (e: InnerFocusEvent) => {
  if (!field.touched) {
    field.touch();
  }
  if (outerOnBlur) {
    outerOnBlur(e);
  }
};

const innerOnChange = (field: NavCarrierFormControl, outerOnChange?: (e: InnerChangeEvent) => any) => (e: InnerChangeEvent) => {
  if (field.value !== e.target.checked) {
    field.setValue(e.target.checked);
  }
  if (outerOnChange) {
    outerOnChange(e);
  }
};

interface OwnProps {
  field: NavCarrierFormControl;
  id?: string;
  name?: string;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => any;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => any;
}

export const Checkbox: FunctionComponent<OwnProps> = ({field, ...props}) => {
  useSubscribeToFormValueChanges(field);
  useSubscribeToFormDisabledChanges(field);
  return field
    ? <input
        {...props}
        disabled={props.disabled ?? field.disabled}
        type="checkbox"
        checked={field.value || false}
        onChange={innerOnChange(field, props.onChange)}
        onBlur={innerOnBlur(field, props.onBlur)}
      />
    : null;
};
