import React from 'react';
import classNames from 'classnames';
import { FunctionComponent, useContext } from 'react';

import { RadioContext } from 'shared/components/form-fields/radio/radio.context';
import { Translation } from 'shared/components/translation/translation.component';

interface OwnProps {
  id?: string;
  value: any;
  resource: ResourceKey;
}

type Props = OwnProps;

export const Radio: FunctionComponent<Props> = ({value, resource, id}) => {
  const {selection, name, onChange} = useContext(RadioContext);
  return(
    <label className={classNames('btn', selection === value ? 'btn-primary' : 'btn-default')}>
      <input
        type="radio"
        name={name}
        id={id}
        value={value}
        checked={selection === value}
        onChange={onChange}
      />
      <Translation resource={resource}/>
    </label>
  );
};
