import React from 'react';
import classNames from 'classnames';
import { FunctionComponent, ReactNode, ChangeEvent, FocusEvent } from 'react';

import { Label } from 'shared/components/elements/elements.components';
import { Translation } from 'shared/components/translation/translation.component';
import { NavCarrierFormControl } from 'app/forms/control/form-field';
import { useFormControlAsNumericState, useFormControlAsState } from 'app/hooks/forms/use-form-control-as-state.hook';
import { ErrorBoundary } from 'shared/components/error-boundary/error-boundary.component';

interface OwnProps {
  required?: boolean;
  disabled?: boolean;
  id: string;
  name?: string;
  numeric?: boolean;
  className?: string;
  label?: ResourceKey | (() => ReactNode);
  field: NavCarrierFormControl;
  renderErrors?: (field: NavCarrierFormControl) => ReactNode;
  onChange?: (e: ChangeEvent) => any;
  onBlur?: (e: FocusEvent) => any;
  children?: ReactNode;
}

export const Select: FunctionComponent<OwnProps> = ({required, field, label, id, renderErrors, className, children, disabled, onChange, name, onBlur, numeric}) => {
  const textHookValues = useFormControlAsState<string|number|string[]>(field, onChange, onBlur);
  const numericHookValues = useFormControlAsNumericState<number>(field, onChange, onBlur);

  const [value, innerOnChange, innerOnBlur] = numeric ? numericHookValues : textHookValues;

  return (
    <div className={classNames('form-group', {'has-error': field.hasErrors()}, className)}>
      <ErrorBoundary>
        {Boolean(label) &&
        <Label required={required} htmlFor={id}>
          {typeof label === 'function' ? label() : <Translation resource={label}/>}
        </Label>
        }
        <select
          id={id} className="form-control"
          name={name}
          value={value ?? ''} onChange={innerOnChange} onBlur={innerOnBlur}
          disabled={field.disabled || disabled}
        >
          {children}
        </select>
        {typeof renderErrors === 'function' && renderErrors(field)}
      </ErrorBoundary>
    </div>
  );
};
