import React from 'react';
import classNames from 'classnames';
import { FunctionComponent, ReactNode, FocusEvent, ChangeEvent } from 'react';

import { Label } from 'shared/components/elements/elements.components';
import { Translation } from 'shared/components/translation/translation.component';
import { NavCarrierFormControl } from 'app/forms/control/form-field';
import { useFormControlAsState } from 'app/hooks/forms/use-form-control-as-state.hook';
import { ErrorBoundary } from 'shared/components/error-boundary/error-boundary.component';

interface OwnProps {
  id: string;
  className?: string;
  required?: boolean;
  suppressLabel?: boolean;
  label?: ResourceKey | (() => ReactNode);
  field: NavCarrierFormControl;
  renderErrors?: (field: NavCarrierFormControl) => ReactNode;
  onChange?: (e: ChangeEvent) => any;
  onBlur?: (e: FocusEvent) => any;
  disabled?: boolean;
  type?: string;
  name?: string;
  title?: string;
  placeholder?: string;
  rows?: number;
  cols?: number;
}

export const Textarea: FunctionComponent<OwnProps> = ({field, required, label, renderErrors, className, suppressLabel, onChange, onBlur, ...props}) => {
  const [value, innerOnChange, innerOnBlur] = useFormControlAsState<string>(field, onChange, onBlur);
  return (
    <div className={classNames('form-group', {'has-error': field.hasErrors()}, className)}>
      <ErrorBoundary>
        {Boolean(label) && !suppressLabel &&
        <Label required={required} htmlFor={props.id}>
          {typeof label === 'function' ? label() : <Translation resource={label}/>}
        </Label>
        }
        <textarea
          {...props}
          className="form-control" disabled={field.disabled || props.disabled}
          value={value} onChange={innerOnChange} onBlur={innerOnBlur}
        />
        {typeof renderErrors === 'function' && renderErrors(field)}
      </ErrorBoundary>
    </div>
  );
};
