import { Moment } from 'moment';
import { ChangeEvent, FocusEvent, useCallback } from 'react';

import { NavCarrierFormControl } from 'app/forms/control/form-field';
import { useUpdateOnFormChanges } from 'app/hooks/forms/use-update-on-form-changes.hooks';

type OnChangeFn = (e: ChangeEvent) => any;
type OnFocusFn = (e: FocusEvent) => any;

interface UseFormControlAsStateHook {
  <ReturnType>(field: NavCarrierFormControl, onChange?: OnChangeFn, onBlur?: OnFocusFn, onFocus?: OnFocusFn): [ReturnType, OnChangeFn, OnFocusFn, OnFocusFn];
}

export const useFormControlAsState: UseFormControlAsStateHook = (field, onChange?, onBlur?, onFocus?) => {
  const onChangeCallback = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    field.setValue(e.target.value);
    if (onChange && typeof onChange === 'function') {
      onChange(e);
    }
  }, [field, onChange]);

  const onBlurCallback = useCallback((e: FocusEvent<HTMLInputElement>) => {
    field.touch();
    if (onBlur && typeof onBlur === 'function') {
      onBlur(e);
    }
  }, [field, onBlur]);

  const onFocusCallback = useCallback((e: FocusEvent<HTMLInputElement>) => {
    if (onFocus && typeof onFocus === 'function') {
      onFocus(e);
    }
  }, [field, onBlur]);

  useUpdateOnFormChanges(field);

  return [field.value ?? '', onChangeCallback, onBlurCallback, onFocusCallback];
};

type OnDatePickerChangeFn = (date: Moment) => any;
type OnDatePickerBlurFn = () => any;

interface UseFormControlAsDatePickerStateHook {
  (field: NavCarrierFormControl, onChange?: OnDatePickerChangeFn, onBlur?: OnDatePickerBlurFn): [Moment, OnDatePickerChangeFn, OnDatePickerBlurFn];
}

export const useFormControlAsDatePickerState: UseFormControlAsDatePickerStateHook = (field, onChange?, onBlur?) => {
  const onChangeCallback = useCallback(value => {
    field.setValue(value);
    if (onChange && typeof onChange === 'function') {
      onChange(value);
    }
  }, [field]);
  const onBlurCallback = useCallback(() => {
    field.touch();
    if (onBlur && typeof onBlur === 'function') {
      onBlur();
    }
  }, [field]);

  useUpdateOnFormChanges(field);

  return [field.value ?? '', onChangeCallback, onBlurCallback];
};

export const useFormControlAsNumericState: UseFormControlAsStateHook = (field, onChange?, onBlur?, onFocus?) => {
  const onChangeCallback = useCallback((e: ChangeEvent<HTMLInputElement|HTMLSelectElement>) => {
    field.setValue(e.target.value != null && e.target.value !== '' ? Number(e.target.value) : null);
    if (onChange && typeof onChange === 'function') {
      onChange(e);
    }
  }, [field, onChange]);

  const onBlurCallback = useCallback((e: FocusEvent<HTMLInputElement|HTMLSelectElement>) => {
    field.touch();
    if (onBlur && typeof onBlur === 'function') {
      onBlur(e);
    }
  }, [field, onBlur]);

  const onFocusCallback = useCallback((e: FocusEvent<HTMLInputElement|HTMLSelectElement>) => {
    if (onFocus && typeof onFocus === 'function') {
      onFocus(e);
    }
  }, [field, onBlur]);

  useUpdateOnFormChanges(field);

  return [field.value ?? null, onChangeCallback, onBlurCallback, onFocusCallback];
};

type OnHourMinutePickerChangeFn = (date: Date) => any;
type OnHourMinutePickerBlurFn = () => any;

interface UseFormControlAsHourMinutePickerStateHook {
  (field: NavCarrierFormControl, onChange?: OnHourMinutePickerChangeFn, onBlur?: OnHourMinutePickerBlurFn):
    [Date, OnHourMinutePickerChangeFn, OnHourMinutePickerBlurFn];
}

export const useFormControlAsHourMinutePickerState: UseFormControlAsHourMinutePickerStateHook = (field, onChange?, onBlur?) => {
  const onChangeCallback = useCallback(value => {
    field.setValue(value);
    if (onChange && typeof onChange === 'function') {
      onChange(value);
    }
  }, [field]);
  const onBlurCallback = useCallback(() => {
    field.touch();
    if (onBlur && typeof onBlur === 'function') {
      onBlur();
    }
  }, [field]);

  useUpdateOnFormChanges(field);

  return [field.value ?? '', onChangeCallback, onBlurCallback];
};
